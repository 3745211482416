import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { AttributesQueryKey } from 'common/hooks/api/queries/use-attributes-query';
import { TableContainerWithLoading } from 'common/ui/containers/table-container-with-loading';
import { TableRowActions } from 'common/ui/shared/table-row-actions';
import { format } from 'date-fns';
import { ChangeEvent, MouseEvent, ReactElement, useState } from 'react';
import { Styles } from 'common/types/styles';
import { useConditionalInstructionsDeleteMutation } from 'common/hooks/api/mutations/use-attributes-mutation';
import toast from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';
import AddIcon from '@mui/icons-material/Add';
import { ApproveModal } from 'common/ui/shared/approve-modal';
import { approveDeleteVocabulary } from 'common/constants/vocabulary';
import { GenerateSpecialInstructionResponse } from 'common/services/api/attributes/attributes-api.types';
import { useInstructionContext } from '../instruction-context';
import { useTableContext } from '../table-context';
import { GenerateInstruction } from '../generate-instruction';
import { ConditionalInstructionApplicabilityScope, initialInstructionEntry } from '../constants';

const styles: Styles = {
  container: { mt: 3 },
  pagination: { mt: 'auto' },
  emptyContainer: { display: 'flex', alignItems: 'center', gap: 2 },
};

export function InstructionsTable(): ReactElement {
  const queryClient = useQueryClient();

  const { mutate: mutateInstructionDelete } = useConditionalInstructionsDeleteMutation();

  const { setInstructionModalOpen, setSelectedInstruction } = useInstructionContext();
  const { instructions, loading, pagination } = useTableContext();

  const [targetDeleteInstruction, setTargetDeleteInstruction] = useState<number | string>(null);

  const handleInstructionDelete = () => {
    setTargetDeleteInstruction(null);

    if (!targetDeleteInstruction) return;

    mutateInstructionDelete(
      { id: targetDeleteInstruction },
      {
        onSuccess: () => {
          toast.success('Conditional instruction deleted successfully');
          queryClient.invalidateQueries([AttributesQueryKey.ConditionalInstructions]);
          queryClient.invalidateQueries([AttributesQueryKey.ProductSpecialInstructions]);
        },
      }
    );
  };

  const handleChangePage = (_: MouseEvent<HTMLButtonElement>, page: number) => {
    pagination.setPage(page);
  };

  const handleChangeLimit = (e: ChangeEvent<HTMLInputElement>) => {
    pagination.setLimit(Number(e.target.value));
  };

  const handleChangeInstruction = (value: GenerateSpecialInstructionResponse) => {
    setSelectedInstruction({
      id: 'new',
      name: value?.conditionalInstructionParts?.name ?? '',
      instruction: value?.conditionalInstructionParts?.instruction ?? '',
      conditions: value?.conditionalInstructionParts?.conditions ?? '',
      created_at: null,
      updated_at: null,
      applicability_scope: ConditionalInstructionApplicabilityScope.All,
    });

    setInstructionModalOpen(true);
  };

  if (!loading && !instructions.length) {
    return (
      <Box sx={styles.emptyContainer}>
        <Typography>Create your first rule</Typography>

        <GenerateInstruction
          onChangeInstruction={handleChangeInstruction}
          onManual={() => {
            setSelectedInstruction({ ...initialInstructionEntry });
            setInstructionModalOpen(true);
          }}
          button={
            <Button variant="contained">
              <AddIcon />
              Create Rule
            </Button>
          }
        />
      </Box>
    );
  }

  return (
    <>
      <TableContainerWithLoading sx={styles.container} loading={loading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Updated At</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {instructions.map(instr => (
              <TableRow key={instr.id}>
                <TableCell>{instr.name}</TableCell>
                <TableCell>{format(new Date(instr?.created_at), 'yyyy-MM-dd hh:mm a')}</TableCell>
                <TableCell>
                  <TableRowActions
                    show={['edit', 'delete']}
                    onEdit={() => {
                      setSelectedInstruction(instr);
                      setInstructionModalOpen(true);
                    }}
                    onDelete={() => setTargetDeleteInstruction(instr.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ApproveModal
          open={Boolean(targetDeleteInstruction)}
          onClose={() => setTargetDeleteInstruction(null)}
          onConfirm={handleInstructionDelete}
          vocabulary={approveDeleteVocabulary}
        />
      </TableContainerWithLoading>
      {pagination && (
        <TablePagination
          sx={styles.pagination}
          component="div"
          rowsPerPageOptions={[10, 20, 50]}
          count={pagination.total ?? 0}
          rowsPerPage={pagination.limit}
          page={pagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeLimit}
        />
      )}
    </>
  );
}
