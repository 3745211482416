import { Box, Drawer } from '@mui/material';
import { paper } from 'common/constants/colors';
import { getMainNavConfig, getSettingsNavConfig, NavItem } from 'common/constants/nav';
import { theme } from 'common/constants/theme';
import { useMenuWidth } from 'common/hooks/use-menu-width';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Styles } from 'common/types/styles';
import { userService } from 'common/services/user.service';
import { RetailerSelect } from 'common/ui/inputs/retailer/retailer-select';
import { AdvonLogoIcon } from 'common/svg/advon-logo-icon';
import { DemoBlurWrapper } from 'common/ui/containers/demo-blur-wrapper';
import { useDemoContext } from 'common/ui/containers/demo-context';
import { NavItems } from './nav-items';

interface StylesProps {
  menuWidth: number;
  withRetailer: boolean;
}

const getStyles = ({ menuWidth, withRetailer }: StylesProps): Styles => ({
  menuContainer: {
    width: menuWidth,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.palette.primary.dark,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    padding: theme.spacing(3.5, 0, 5),
  },
  brand: { alignSelf: 'center', color: theme.palette.background.paper, fontWeight: 700 },
  navItemsWrapper: { marginTop: withRetailer ? '50px' : '90px' },
  drawer: { border: 'none' },
  logo: { color: paper, fontSize: 16, fontWeight: 400, letterSpacing: 1, marginLeft: 0.5 },
  greyedLogo: { color: 'rgba(138, 139, 148, 0.65)' },
  logoContainer: { display: 'flex', alignItems: 'center', marginLeft: 5, marginBottom: 1 },
  retailerWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: '5px',
    padding: '0 40px',
    boxSizing: 'border-box',
    mt: 1,
  },
  retailerSelect: {
    color: theme.palette.grey[300],
    fontSize: 14,
  },
});

interface Props {
  open: boolean;
}

export function Menu({ open }: Props) {
  const { t } = useTranslation();
  const { active } = useDemoContext();

  const menuWidth = useMenuWidth();

  const styles = getStyles({ menuWidth, withRetailer: userService.isAdmin() || userService.ensureLevel('L1') });

  const config = useMemo(() => {
    const searchAvailable = (nested: NavItem[]): NavItem[] => {
      if (!nested) return undefined;

      return nested
        .filter(item => item.isAvailable && (item.nested !== undefined ? searchAvailable(item.nested).length : true))
        .map(item => ({ ...item, nested: item.nested !== undefined ? searchAvailable(item.nested) : undefined }));
    };

    return searchAvailable(getMainNavConfig(t));
  }, [t]);

  return (
    <Drawer PaperProps={{ sx: styles.drawer }} anchor="left" variant="persistent" open={open}>
      <Box sx={styles.menuContainer}>
        <Box sx={styles.logoContainer}>
          <AdvonLogoIcon color="#fff" />
        </Box>
        {(userService.isAdmin() || userService.ensureLevel('L1') || userService.ensureLevel('L3')) && (
          <DemoBlurWrapper>
            <Box sx={styles.retailerWrapper}>
              <RetailerSelect sx={styles.retailerSelect} fullWidth demoBlur={active} />
            </Box>
          </DemoBlurWrapper>
        )}
        <Box sx={styles.navItemsWrapper}>
          <NavItems items={config} />
        </Box>
        <Box sx={styles.navItemsWrapper}>
          <NavItems items={getSettingsNavConfig(t).filter(item => item.isAvailable)} />
        </Box>
      </Box>
    </Drawer>
  );
}
