import { AIInstructionEntry } from 'common/types/common';
// eslint-disable-next-line import/no-cycle
import { Condition } from './expression-block';

export const initialConditionEntry: Condition = {
  field: '',
  operator: '',
  value: '',
};

export const instructionFormSubmitBtnID = 'instructionFormSubmitBtn';

export enum ConditionalInstructionApplicabilityScope {
  Attributes = 'attributes',
  PDP = 'pdp',
  All = 'all',
}

export const initialInstructionEntry: AIInstructionEntry = {
  id: 'new',
  name: '',
  instruction: '',
  conditions: '',
  created_at: null,
  updated_at: null,
  applicability_scope: ConditionalInstructionApplicabilityScope.All,
};
