import { useAuth0 } from '@auth0/auth0-react';
import { Box, Divider, IconButton, Popover, Typography } from '@mui/material';
import { Styles } from 'common/types/styles';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import { MouseEvent, ReactElement, useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { getAvatarStyles } from 'common/utils/styles';
import { useMeQuery } from 'common/hooks/api/queries/use-users-query';
import PreviewIcon from '@mui/icons-material/Preview';
import { useDemoContext } from 'common/ui/containers/demo-context';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import { userService } from 'common/services/user.service';
import PasswordIcon from '@mui/icons-material/Password';
import { ActionsContainer, ActionsOption } from 'common/ui/inputs/actions-popover';
import { useNavigate } from 'react-router-dom';

enum Action {
  Logout = 0,
  ChangePassword,
}

const actionOptions: ActionsOption[] = [
  {
    name: 'Logout',
    value: Action.Logout,
    icon: <LogoutIcon fontSize="small" />,
  },
  {
    name: 'Change Password',
    value: Action.ChangePassword,
    icon: <PasswordIcon fontSize="small" />,
  },
];

const styles: Styles = {
  userContainer: { display: 'flex', alignItems: 'center', gap: 1.5 },
  avatar: { cursor: 'pointer' },
  popover: { p: 2, display: 'flex', flexDirection: 'column', gap: 1.5 },
  popoverName: { display: 'flex', alignItems: 'center', gap: 1 },
  popoverItems: { display: 'flex', flexDirection: 'column' },
};

export function AuthUserControls(): ReactElement {
  const nav = useNavigate();
  const { user, logout } = useAuth0();
  const { active, setActive } = useDemoContext();

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement>(null);

  const { data: meData } = useMeQuery();

  const handlePopoverOpen = (e: MouseEvent<HTMLElement>) => {
    setPopoverAnchor(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const handleActionClick = (v: number | string) => {
    switch (v) {
      case Action.Logout:
        logout({ returnTo: window.location.origin });
        break;
      case Action.ChangePassword:
        nav('/settings/password');
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={styles.userContainer}>
      {userService.isAdmin() && (
        <LightTooltip title="Demo Mode">
          <IconButton color={active ? 'primary' : 'default'} onClick={() => setActive(!active)}>
            <PreviewIcon fontSize="small" />
          </IconButton>
        </LightTooltip>
      )}
      <Box sx={[getAvatarStyles(meData?.user.picture, 36), styles.avatar]} onClick={handlePopoverOpen} />
      <Popover
        anchorEl={popoverAnchor}
        open={Boolean(popoverAnchor)}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={styles.popover}>
          <Box sx={styles.popoverName}>
            <Typography>{user?.name}</Typography>
            <WavingHandIcon color="action" />
          </Box>
          <Divider />
          <Box sx={styles.popoverItems}>
            <ActionsContainer options={actionOptions} onClick={handleActionClick} />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
