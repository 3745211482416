import { PocRetailers, POCSubVendors, RetailerType, SubRetailers } from 'common/constants/entities';
import { User, UserFlag } from 'common/types/common';

export class UserService {
  private permissions: Array<string> = [];

  private roles: Array<string> = [];

  private level = '';

  private user: User = null;

  private retailer = '';

  private flags: Record<UserFlag, 0 | 1>;

  setPermissions = (permissions: Array<string>) => {
    this.permissions = permissions;
  };

  setRoles = (roles: Array<string>) => {
    this.roles = roles;
  };

  setFlags = (flags: Record<UserFlag, 0 | 1>) => {
    this.flags = flags;
  };

  setUser = (user: User) => {
    this.user = user;
  };

  setRetailer = (retailer: string) => {
    this.retailer = retailer;
  };

  setLevel = (level: string) => {
    this.level = level;
  };

  getUser = () => {
    return this.user;
  };

  getRetailer = () => {
    return this.retailer;
  };

  getSubRetailer = () => {
    if (!this.ensureRetailers(SubRetailers) && !this.ensureRetailers(PocRetailers)) return null;
    return this.ensureRetailers(PocRetailers)
      ? POCSubVendors[this.retailer as unknown as POCSubVendors]
      : this.retailer;
  };

  getAPIRetailer = () => {
    if (this.ensureRetailers(SubRetailers)) {
      return RetailerType.Target;
    }
    if (this.ensureRetailers(PocRetailers)) {
      return RetailerType.POC;
    }

    return this.retailer;
  };

  ensureRetailer = (retailer: string, overrideRetailer?: string) => {
    return this.retailer === (overrideRetailer ?? retailer);
  };

  ensureRetailers = (retailers: string[], overrideRetailer?: string) => {
    return retailers.includes(overrideRetailer ?? this.retailer);
  };

  ensurePermissions = (permissions: Array<string>) => {
    if (!this.permissions?.length) return false;
    return permissions.every(requestedPermission =>
      this.permissions.some(permission => permission === requestedPermission)
    );
  };

  ensureLevel = (level: string) => {
    return this.level === level;
  };

  ensureFlag = (flag: UserFlag) => {
    return this.flags[flag] === 1;
  };

  isAdmin = () => {
    return this.roles.includes('ccs_admin');
  };

  isL2 = () => {
    return this.roles.includes('ccs_l2');
  };

  isL3 = () => {
    return this.roles.includes('ccs_l3');
  };
}

export const userService = new UserService();
