import { PdpAIModal } from 'common/ui/shared/pdp-ai-modal';
import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { ConditionalInstructionsModal } from 'common/ui/shared/conditional-instructions-modal';
import { useSelectedContentContext } from 'common/ui/shared/selected-content-ctx';
import { getCategoryIDKeyFromProduct } from 'modules/common/content/utils/mappings';
import { KeywordsSettingsModal } from '../keywords-settings-modal';
import { useSelectedProductQuery } from '../../hooks/queries';

const modalsCtx = createContext({
  triggerAIModal: (): void => undefined,
  triggerInstructionsModal: (): void => undefined,
  triggerKWsModal: (): void => undefined,
});

export function ModalsContext({ children }: PropsWithChildren) {
  const { selectedContent } = useSelectedContentContext();

  const { data: productData } = useSelectedProductQuery();

  const [kwsModalOpen, setKWsModalOpen] = useState<boolean>(false);
  const [isAIModalOpen, setIsAIModalOpen] = useState<boolean>(false);
  const [instructionsModalOpen, setInstructionsModalOpen] = useState<boolean>(false);

  const triggerAIModal = useCallback(() => {
    setIsAIModalOpen(true);
  }, []);

  const onAIModalClose = () => {
    setIsAIModalOpen(false);
  };

  const triggerInstructionsModal = useCallback(() => {
    setInstructionsModalOpen(true);
  }, []);

  const onInstructionsModalClose = () => {
    setInstructionsModalOpen(false);
  };

  const triggerKWsModal = useCallback(() => {
    setKWsModalOpen(true);
  }, []);

  const onKWsModalClose = () => {
    setKWsModalOpen(false);
  };

  const value = useMemo(() => {
    return {
      triggerAIModal,
      triggerInstructionsModal,
      triggerKWsModal,
    };
  }, [triggerAIModal, triggerInstructionsModal, triggerKWsModal]);

  return (
    <modalsCtx.Provider value={value}>
      <PdpAIModal pid={selectedContent} open={isAIModalOpen} onClose={onAIModalClose} />
      <KeywordsSettingsModal
        open={kwsModalOpen}
        onClose={onKWsModalClose}
        pid={selectedContent}
        kwTimeRange={productData?.seo_keywords_time_range}
      />
      <ConditionalInstructionsModal
        open={instructionsModalOpen}
        onClose={onInstructionsModalClose}
        pid={selectedContent}
        categoryID={(productData ?? {})[getCategoryIDKeyFromProduct()]}
      />
      {children}
    </modalsCtx.Provider>
  );
}

export function useModalsContext() {
  const ctx = useContext(modalsCtx);
  return ctx;
}
