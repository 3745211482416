import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useCategoriesSearchQuery } from 'common/hooks/api/queries/use-category-query';
import { CategoriesSearchFilters } from 'common/services/api/category/category-api.types';
import { SearchedCategory } from 'common/types/common';
import { SelectFieldOption } from 'common/ui/inputs/select-field';
import { userService } from 'common/services/user.service';
import { ContentStatus, WalmartRetailers } from 'common/constants/entities';

export type OmitedCategoriesSearchFilters = Partial<Omit<CategoriesSearchFilters, 'limit' | 'offset' | 's' | 'counts'>>;

interface Props {
  categoryIDs?: Array<number | string>;
  filters?: OmitedCategoriesSearchFilters;
  enabled?: boolean;
  globalSearch?: boolean;
  useName?: boolean;
}

export function useCategoriesOptionsSearch(props?: Props) {
  const [selectedCategoriesBuffer, setSelectedCategoriesBuffer] = useState<SearchedCategory[]>([]);

  const [categoriesSearch, setCategoriesSearch] = useState<string>('');

  const memoisedFilters: CategoriesSearchFilters = useMemo(() => {
    const getStatuses = (values: Array<string>) => {
      if (values?.includes(ContentStatus.NoIssues)) {
        return Object.values(ContentStatus).filter(item => item !== ContentStatus.NoIssues);
      }
      return values;
    };

    return {
      s: categoriesSearch,
      limit: 250,
      offset: 0,
      scopeID: props?.filters?.scopeID,
      counts: 1,
      wsScope: props?.filters?.wsScope,
      actor_ids: props?.filters?.actor_ids,
      statuses: getStatuses(props?.filters?.statuses),
      skip_level: props?.filters?.skip_level,
      sub_vendor_id: props?.filters?.sub_vendor_id,
    };
  }, [
    categoriesSearch,
    props?.filters?.actor_ids,
    props?.filters?.scopeID,
    props?.filters?.skip_level,
    props?.filters?.statuses,
    props?.filters?.wsScope,
    props?.filters?.sub_vendor_id,
  ]);

  const [categoriesFilters] = useDebounce<CategoriesSearchFilters>(memoisedFilters, 500);

  const { data, isFetching: isCategoriesFetching } = useCategoriesSearchQuery(
    categoriesFilters,
    Boolean(
      (props?.globalSearch ? true : categoriesFilters?.scopeID) &&
        (Object.hasOwn(props ?? {}, 'enabled') ? props?.enabled : true)
    )
  );

  const categoriesData = userService.ensureRetailers(WalmartRetailers)
    ? data?.categories
    : data?.categories?.map(item => ({ ...item, id: item?.external_id }));

  const categoriesOptions = useMemo(() => {
    if (!categoriesData) return [];

    const getOption = (entry: SearchedCategory) => {
      return { label: `${entry.name} (${entry?.productsCount ?? 0})`, value: props.useName ? entry.name : entry.id };
    };

    const options: Array<SelectFieldOption> = [];

    if (selectedCategoriesBuffer?.length) {
      options.push(
        ...selectedCategoriesBuffer
          .filter(bufferC => !categoriesData.some(category => category.id === bufferC.id))
          .map(bufferC => getOption(bufferC))
      );
    }

    categoriesData.forEach(c => options.push(getOption(c)));

    return options;
  }, [categoriesData, props.useName, selectedCategoriesBuffer]);

  useEffect(() => {
    if (!props?.categoryIDs) return;
    setSelectedCategoriesBuffer(categoriesData?.filter(c => props?.categoryIDs?.includes(c.id)) ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.categoryIDs]);

  return { categoriesOptions, isCategoriesFetching, categoriesSearch, setCategoriesSearch };
}
