import { useMutation } from '@tanstack/react-query';
import { RetailerType, SyndigoRetailers } from 'common/constants/entities';
import { attributesApi } from 'common/services/api/attributes/attributes-api.service';
import {
  AcceptAIValidationBody,
  CheckInstructionApplicabilityParams,
  ControlAIQueue,
  DeleteConditionalInstructionsData,
  DeletePDPKeywordsBody,
  DeleteProductAttributesBody,
  EnhanceSpecialInstructionData,
  EnhanceTemplateParams,
  GenerateAIForCategoriesBody,
  GenerateAIForCategoryBody,
  GenerateAIForScopeBody,
  GenerateAIForWPIDSingleAttributeBody,
  GenerateAiSyncData,
  GenerateSpecialInstructionBody,
  GenereateAIForTCINBody,
  KeywordsFilters,
  PatchAttributeFlagsBody,
  PatchPDPKeywordsBody,
  PatchScopeStatusBody,
  PatchWarningRulesBody,
  PostAttributesGroupsBody,
  PostImagen3GenerateBody,
  PostPDPKeywordsBody,
  ResetAtributeRulesBody,
  RestoreAIGenerationBody,
  SaveAttributeRulesBody,
  SavePromptTemplateBodyV2,
  SelectDefaultTemplateBody,
  SendEnhanceNotificationBody,
  UpdateConditionalInstructionsBody,
  ValidateAIBody,
} from 'common/services/api/attributes/attributes-api.types';
import { userService } from 'common/services/user.service';
import { GlobalAISettings } from 'common/types/common';

export function useMappingPostMutation() {
  const templateMutation = useMutation({
    mutationFn: (data: Record<string, string[]>) => attributesApi.postMappings(data),
  });

  return templateMutation;
}

export function useAttributeFlagsPatchMutation() {
  const attributeMutation = useMutation({
    mutationFn: attributesApi.patchAttributeFlags,
  });

  return attributeMutation;
}

export function useRetailerAttributeFlagsPatchMutation() {
  const attributeMutation = useMutation({
    mutationFn: (data: PatchAttributeFlagsBody) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      return attributesApi.patchRetailerAttributeFlags(retailer, data);
    },
  });

  return attributeMutation;
}

export function useAttributesFlagsPatchMutation() {
  const attributeMutation = useMutation({
    mutationFn: (data: Array<PatchAttributeFlagsBody>) => {
      const promises = data.map(v => attributesApi.patchAttributeFlags(v));
      return Promise.all(promises);
    },
  });

  return attributeMutation;
}

export function useRetailerAttributesFlagsPatchMutation(category_id?: string | number) {
  const attributeMutation = useMutation({
    mutationFn: (data: Array<PatchAttributeFlagsBody>) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      const promises = data.map(v => attributesApi.patchRetailerAttributeFlags(retailer, v, category_id));
      return Promise.all(promises);
    },
  });

  return attributeMutation;
}

export function useGenerateAIMutation() {
  const attributeMutation = useMutation({
    mutationFn: attributesApi.generateAI,
  });

  return attributeMutation;
}

export function useGenerateAIForWPIDSingleAttributeMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: GenerateAIForWPIDSingleAttributeBody) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      const data = await attributesApi.generateAIForWPIDSingleAttribute(body, retailer);
      return data;
    },
  });

  return attributeMutation;
}

export function useGenerateAIForWPIDAllAttributesMutation() {
  const attributeMutation = useMutation({
    mutationFn: attributesApi.generateAIForWPIDAllAttributes,
  });

  return attributeMutation;
}

export function useGenerateAIForCategoryMutation() {
  const attributeMutation = useMutation({
    mutationFn: (body: GenerateAIForCategoryBody) => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      const data = attributesApi.generateAIForCategory(body, retailer);
      return data;
    },
  });

  return attributeMutation;
}

export function useGenerateAIForCategoriesMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: GenerateAIForCategoriesBody) => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      const data = await attributesApi.generateAIForCategories(body, retailer);
      return data;
    },
  });

  return attributeMutation;
}

export function useGenerateAIForScopeMutation() {
  const attributeMutation = useMutation({
    mutationFn: attributesApi.genereateAIForScope,
  });

  return attributeMutation;
}

export function useGenerateTargetAIForCategoryMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: GenerateAIForCategoryBody) => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      return attributesApi.generateTargetAIForCategory(body, retailer);
    },
  });

  return attributeMutation;
}

export function useGenerateTargetAIForCategoriesMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: GenerateAIForCategoriesBody) => {
      const retailer =
        userService.ensureRetailers(SyndigoRetailers) || userService.getRetailer() === RetailerType.Autozone
          ? RetailerType.Target.toLowerCase()
          : userService.getAPIRetailer().toLowerCase();
      return attributesApi.generateTargetAIForCategories(body, retailer);
    },
  });

  return attributeMutation;
}

export function useGenerateTargetAIForScopeMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: GenerateAIForScopeBody) => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      return attributesApi.genereateTargetAIForScope(body, retailer);
    },
  });

  return attributeMutation;
}

export function useEnhanceTemplateMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: EnhanceTemplateParams) => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      return attributesApi.enhanceTemplate(body, retailer);
    },
  });

  return attributeMutation;
}

export function useSavePromptTemplateMutation() {
  const attributeMutation = useMutation({
    mutationFn: attributesApi.savePromptTemplate,
  });

  return attributeMutation;
}

export function useSavePromptTemplateMutationV2() {
  const attributeMutation = useMutation({
    mutationFn: async (body: SavePromptTemplateBodyV2) => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      return attributesApi.savePromptTemplateV2(body, retailer);
    },
  });

  return attributeMutation;
}

export function useAttributesGroupsMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: PostAttributesGroupsBody) => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      return attributesApi.postAttributesGroups(body, retailer);
    },
  });

  return attributeMutation;
}

export function useGenerateAIForTCIN(retailer?: RetailerType) {
  const attributeMutation = useMutation({
    mutationFn: async (body: GenereateAIForTCINBody) => {
      const apiRetailer = (retailer || userService.getAPIRetailer())?.toLowerCase();
      return attributesApi.genereateAIForTCIN(body, apiRetailer);
    },
  });

  return attributeMutation;
}

export function useAttributeRulesSave() {
  const attributeMutation = useMutation({
    mutationFn: async (body: SaveAttributeRulesBody) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      return attributesApi.saveAttributeRules(body, retailer);
    },
  });

  return attributeMutation;
}

export function useAttributeRulesReset() {
  const attributeMutation = useMutation({
    mutationFn: async (body: ResetAtributeRulesBody) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      return attributesApi.resetAttributeRules(body, retailer);
    },
  });

  return attributeMutation;
}

export function useValidateAIMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: ValidateAIBody) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      return attributesApi.validateAI(retailer, body);
    },
  });

  return attributeMutation;
}

export function useAcceptAIValidationMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: AcceptAIValidationBody) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      return attributesApi.acceptAIValidation(retailer, body);
    },
  });

  return attributeMutation;
}

export function useSelectDefaultTemplateMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: SelectDefaultTemplateBody) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      return attributesApi.selectDefaultTemplate(body, retailer);
    },
  });

  return attributeMutation;
}

export function useConditionalInstructionsPostMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: UpdateConditionalInstructionsBody) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      return attributesApi.postConditionalInstructions(body, retailer);
    },
  });

  return attributeMutation;
}

export function useConditionalInstructionsPatchMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: UpdateConditionalInstructionsBody) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      return attributesApi.patchConditionalInstructions(body, retailer);
    },
  });

  return attributeMutation;
}

export function useConditionalInstructionsDeleteMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: DeleteConditionalInstructionsData) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      return attributesApi.deleteConditionalInstructions(retailer, body);
    },
  });

  return attributeMutation;
}

export function useGenerateAiSyncMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: GenerateAiSyncData) => {
      const retailer =
        userService.ensureRetailers(SyndigoRetailers) || userService.getRetailer() === RetailerType.Autozone
          ? RetailerType.Target.toLowerCase()
          : userService.getAPIRetailer()?.toLowerCase();
      return attributesApi.generateAiSync(retailer, body);
    },
  });

  return attributeMutation;
}

export function useGenerateWalmartAiSyncMutation() {
  const attributeMutation = useMutation({
    mutationFn: async (body: GenerateAiSyncData) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      const data = await attributesApi.generateWalmartAiSync(body, retailer);
      return data;
    },
  });

  return attributeMutation;
}

export function useEnhanceSpecialInstructionMutation(retailer?: RetailerType) {
  const mutation = useMutation({
    mutationFn: (data: EnhanceSpecialInstructionData) => {
      const currRetailer =
        userService.ensureRetailers(SyndigoRetailers) || userService.getRetailer() === RetailerType.Autozone
          ? RetailerType.Target
          : retailer;
      return attributesApi.enhanceSpecialInstruction(
        (currRetailer || userService.getAPIRetailer()) as RetailerType,
        data
      );
    },
  });

  return mutation;
}

export function useGenerateSwatchMutation() {
  const mutation = useMutation({
    mutationFn: attributesApi.generateSwatchImage,
  });

  return mutation;
}

export function useScrapeFromURLMutation() {
  const mutation = useMutation({
    mutationFn: attributesApi.scrapeFromURL,
  });

  return mutation;
}

export function useDeleteProductAttributesMutation() {
  const mutation = useMutation({
    mutationFn: async (data: { tcin: string; body: DeleteProductAttributesBody }) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      return attributesApi.deleteProductAttributes(retailer, data.tcin, data.body);
    },
  });

  return mutation;
}

export function useAddProductImagesMutation() {
  const mutation = useMutation({
    mutationFn: async (data: { tcin: string; images: Array<File> }) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      return attributesApi.addProductImages(retailer, data.tcin, data.images);
    },
  });

  return mutation;
}

export function useDeleteProductImagesMutation() {
  const mutation = useMutation({
    mutationFn: async (data: { tcin: string; images: Array<string> }) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      return userService.getRetailer() === RetailerType.Walmart
        ? attributesApi.deleteWalmartProductImages(data.tcin, data.images)
        : attributesApi.deleteProductImages(retailer, data.tcin, data.images);
    },
  });

  return mutation;
}

export function useFetchExternalAttributes() {
  const mutation = useMutation({
    mutationFn: async (tcin: string) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      return attributesApi.fetchExternalAttributes(retailer, tcin);
    },
  });

  return mutation;
}

export function useSendEnhanceNotificationMutation(retailer?: RetailerType) {
  const mutation = useMutation({
    mutationFn: async (body: SendEnhanceNotificationBody) => {
      const apiRetailer = (retailer || userService.getAPIRetailer())?.toLowerCase();
      return attributesApi.sendEnhanceNotification(apiRetailer, body);
    },
  });

  return mutation;
}

export function useUpdatePDPKeywordsMutation() {
  const mutation = useMutation({
    mutationFn: async ({
      post,
      patch,
      del,
    }: {
      post?: PostPDPKeywordsBody;
      patch?: PatchPDPKeywordsBody;
      del?: DeletePDPKeywordsBody;
    }) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      const promises = [];
      if (patch?.entries.length) {
        promises.push(attributesApi.patchPDPKeywords(retailer, patch));
      }
      if (post?.entries.length) {
        attributesApi.postPDPKeywords(retailer, post);
      }
      if (del?.keyword_ids.length) {
        attributesApi.deletePDPKeywords(retailer, del);
      }
      await Promise.all(promises);
    },
  });

  return mutation;
}

export function useAddSuggestedKeyword() {
  const mutation = useMutation({
    mutationFn: async (body: PostPDPKeywordsBody) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      const { data } = await attributesApi.postPDPKeywords(retailer, body);
      return data;
    },
  });

  return mutation;
}

export function useFetchKeywords(filters?: KeywordsFilters) {
  const mutation = useMutation({
    mutationFn: async (tcin: string) => {
      const retailer = userService.getAPIRetailer()?.toLowerCase();
      const { data } = await attributesApi.fetchKeywords(retailer, tcin, filters);
      return data;
    },
  });

  return mutation;
}

export function useCancelBatch() {
  const mutation = useMutation({
    mutationFn: async (batchID: number) => {
      const { data } = await attributesApi.cancelAIBatch(batchID);
      return data;
    },
  });

  return mutation;
}

export function useControlAIQueue() {
  const mutation = useMutation({
    mutationFn: async (control: ControlAIQueue) => {
      const { data } = await attributesApi.controlAIQueue(control);
      return data;
    },
  });

  return mutation;
}

export function useRestoreAIGenerationMutation(retailer?: RetailerType) {
  const mutation = useMutation({
    mutationFn: async (body: RestoreAIGenerationBody) => {
      const apiRetailer = (retailer || userService.getAPIRetailer())?.toLowerCase();
      return attributesApi.restoreAIGeneration(apiRetailer, body);
    },
  });

  return mutation;
}

export function usePatchScopeStatusMutation() {
  const mutation = useMutation({
    mutationFn: async (body: PatchScopeStatusBody) => {
      const retailer = userService.getAPIRetailer();
      return retailer === RetailerType.Walmart
        ? attributesApi.patchScopeStatus(body)
        : attributesApi.patchVendorsScopeStatus(retailer, { ...body, subVendorID: userService.getSubRetailer() });
    },
  });

  return mutation;
}

export function useRetryFailedBatchProducts() {
  const mutation = useMutation({
    mutationFn: async (batchID: number) => {
      const retailer = userService.getAPIRetailer();
      return attributesApi.retryFailedBatchProducts(retailer, batchID);
    },
  });

  return mutation;
}

export function usePatchGlobalAISettings() {
  const mutation = useMutation({
    mutationFn: async (body: Omit<GlobalAISettings, 'updated_at'>) => {
      return attributesApi.patchGlobalAISettings(body);
    },
  });

  return mutation;
}

export function useInstructionApplicabilityMutation() {
  const mutation = useMutation({
    mutationFn: async (params: CheckInstructionApplicabilityParams) => {
      const retailer = userService.getAPIRetailer();
      return attributesApi.checkInstructionApplicability(retailer, params);
    },
  });

  return mutation;
}

export function useGenerateInstructionMutation() {
  const mutation = useMutation({
    mutationFn: async (body: GenerateSpecialInstructionBody) => {
      const retailer = userService.getAPIRetailer();
      return retailer === RetailerType.Walmart
        ? attributesApi.generateWalmartInstruction(body)
        : attributesApi.generateRetailerInstruction(retailer, body);
    },
  });

  return mutation;
}

export function usePostImagen3GenerateMutation() {
  const mutation = useMutation({
    mutationFn: async (body: PostImagen3GenerateBody) => {
      const { data } = await attributesApi.postImagen3Generate(body);
      return data;
    },
  });

  return mutation;
}

export function usePatchWarningRulessMutation() {
  const mutation = useMutation({
    mutationFn: async (body: PatchWarningRulesBody) => {
      const retailer = userService.getAPIRetailer();
      return retailer === RetailerType.Walmart
        ? attributesApi.patchWalmartWarningRules(body)
        : attributesApi.patchWarningRules(retailer, { ...body, sub_vendor_id: userService.getSubRetailer() });
    },
  });

  return mutation;
}
