import Popover from '@mui/material/Popover';
import { theme } from 'common/constants/theme';
import { Box } from '@mui/material';
import { ActionsContainer, ActionsOption } from './actions-container';

interface Props {
  open: boolean;
  options: ActionsOption[];
  anchor: HTMLElement | null;
  onClose: () => void;
  onClick: (value: string | number) => void;
}

export function ActionsPopover({ open, options, anchor, onClose, onClick }: Props) {
  const handleClick = (value: string | number) => {
    onClick(value);
    onClose();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box p={theme.spacing(0, 2.5)}>
        <ActionsContainer options={options} onClick={handleClick} />
      </Box>
    </Popover>
  );
}
