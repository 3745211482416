import { Box, Divider, Popover, Switch, Typography } from '@mui/material';
import { Styles } from 'common/types/styles';
import { ReactElement, useEffect, useState } from 'react';
import { ActionNotification } from 'common/types/common';
import { theme } from 'common/constants/theme';
import { LCLastOpenedKey, useNotificationsContext } from './notifications-context';
import { Notification } from './notification';

const styles: Styles = {
  container: { width: 700, display: 'flex', flexDirection: 'column' },
  head: { p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' },
  notificationsContainer: { display: 'flex', flexDirection: 'column', maxHeight: 250, overflowY: 'auto' },
  notification: { display: 'flex', alignItems: 'center', gap: 2, p: 2 },
  expandBtn: { fontSize: 11, height: 20, color: theme.palette.grey[600] },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 24,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  switchText: { fontSize: 11, color: theme.palette.grey[700] },
  infoText: { p: 2 },
};

interface Props {
  anchor: HTMLElement;
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function NotificationsPopup({ anchor, onClose, onOpen, open }: Props): ReactElement {
  const { notifications, queryLoading, toggleOwnActions, ownActions, setLastOpened, loaded } =
    useNotificationsContext();

  const [latestNotification, setLatestNotification] = useState<ActionNotification>();

  useEffect(() => {
    if (!loaded) return;
    setLatestNotification(notifications[0] ?? null);
  }, [loaded, notifications]);

  useEffect(() => {
    if (!open) return;
    const date = String(new Date().valueOf());
    localStorage.setItem(LCLastOpenedKey, date);
    setLastOpened(date);
  }, [open, setLastOpened]);

  useEffect(() => {
    if (latestNotification === undefined) return;

    if (
      latestNotification?.updated_at !== notifications[0]?.updated_at ||
      latestNotification?.status !== notifications[0]?.status
    )
      onOpen();
  }, [latestNotification, notifications, onOpen]);

  return (
    <Popover
      anchorEl={anchor}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box sx={styles.container}>
        <Box sx={styles.head}>
          <Typography>Notifications</Typography>
          <Box sx={styles.switchContainer}>
            <Typography sx={styles.switchText}>Global</Typography>
            <Switch onChange={toggleOwnActions} checked={ownActions} />
            <Typography sx={styles.switchText}>Own</Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={styles.notificationsContainer}>
          {queryLoading && (
            <Typography variant="caption" sx={styles.infoText}>
              Loading...
            </Typography>
          )}
          {Boolean(!queryLoading && !notifications.length) && (
            <Typography variant="caption" sx={styles.infoText}>
              No notifications
            </Typography>
          )}
          {notifications?.map(n => (
            <Notification key={n.id} notification={n} />
          ))}
        </Box>
      </Box>
    </Popover>
  );
}
