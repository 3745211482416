import { Box, Divider, Typography } from '@mui/material';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types/styles';
import { Fragment, ReactElement } from 'react';

export interface ActionsOption {
  name: string;
  value: string | number;
  icon?: React.ReactNode;
}

const optionHeight = 50;
const iconSize = 10;
const textSize = 12;

const getStyles = (): Styles => ({
  container: { display: 'flex', flexDirection: 'column' },
  option: {
    height: optionHeight,
    fontSize: textSize,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    width: iconSize,
    height: iconSize,
    marginRight: theme.spacing(1),
  },
  text: {
    marginLeft: 1,
    fontSize: 12,
  },
  divider: {
    borderColor: '#F4F4F5',
  },
});

interface Props {
  options: ActionsOption[];
  onClick: (value: string | number) => void;
}

export function ActionsContainer({ options, onClick }: Props): ReactElement {
  const styles = getStyles();

  return (
    <Box sx={styles.container}>
      {options.map((option, index) => (
        <Fragment key={option.value}>
          <Box sx={styles.option} onClick={() => onClick(option.value)}>
            {option.icon}
            <Typography sx={styles.text}>{option.name}</Typography>
          </Box>
          {index !== options.length - 1 && <Divider sx={styles.divider} />}
        </Fragment>
      ))}
    </Box>
  );
}
