import * as Yup from 'yup';
import { ConditionalInstructionApplicabilityScope } from './constants';
import { InstructionForm } from './types';

export const expressionsValidationSchema = Yup.array().of(
  Yup.object().shape({
    conditions: Yup.array().of(
      Yup.object().shape({
        field: Yup.string().required().label('Field'),
        operator: Yup.string().required().label('Operator'),
        value: Yup.mixed()
          .label('Value')
          .when('operator', {
            is: (operator: string) => operator === 'in',
            then: Yup.array().of(Yup.string()).min(1).required(),
            otherwise: Yup.string().required(),
          }),
      })
    ),
  })
);

export const instructionsValidationSchema = Yup.object().shape<Record<keyof InstructionForm, Yup.AnySchema>>({
  id: Yup.mixed(),
  name: Yup.string().required().label('Name'),
  instruction: Yup.string().required().label('Instruction'),
  applicabilityScope: Yup.string()
    .required()
    .label('Execution Scope')
    .oneOf([
      ConditionalInstructionApplicabilityScope.All,
      ConditionalInstructionApplicabilityScope.Attributes,
      ConditionalInstructionApplicabilityScope.PDP,
    ]),
  expressions: expressionsValidationSchema,
});
