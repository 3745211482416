import { AIConditionExpression } from 'common/types/common';
import { ExpressionEntry } from 'common/ui/shared/conditional-instructions-modal/types';
import { tryJSONParse } from 'common/utils/json';

export function transformExpressionsForBackend(expressions: Array<ExpressionEntry>) {
  return JSON.stringify(
    expressions.reduce(
      (rootAcc, rootItem) => {
        rootAcc.or.push({
          and: rootItem.conditions.map(condition => ({
            [condition.field]: { [condition.operator]: condition.value },
          })),
        });
        return rootAcc;
      },
      { or: [] }
    )
  );
}

export function transfromExpressionsForFrontend(expressions: string): Array<ExpressionEntry> {
  const data: AIConditionExpression = tryJSONParse(expressions);

  // it supports only one format
  if (!data || !data.or || !Array.isArray(data.or)) return null;

  return data.or
    .map(expr => {
      const conditions = expr.and as AIConditionExpression[];
      if (!conditions) return null;
      return {
        conditions: conditions.map(condition => {
          const conditionField = Object.keys(condition)[0];
          const [operator, value] = Object.entries(condition[conditionField])[0];
          return {
            field: conditionField,
            operator,
            value,
          };
        }),
      };
    })
    .filter(Boolean);

  // return data.or.map(expr => ({
  //   conditions: Object.keys(expr).map(conditionField => {
  //     const [operator, value] = Object.entries(expr[conditionField])[0];
  //     return {
  //       field: conditionField,
  //       operator,
  //       value,
  //     };
  //   }),
  // }));
}
