import { AxiosResponse } from 'axios';
import { ApiService } from 'common/services';
import { SearchedCategory, TemplateRule } from 'common/types/common';
import { RetailerType, selectAllValue } from 'common/constants/entities';
import {
  CategoriesSearchFilters,
  GetProductTypeBatchesParams,
  GetProductTypeBatchItemsParams,
  ProductTypeBatch,
  ProductTypeBatchItem,
  ProductTypeVerificationBatchBody,
} from './category-api.types';

function parseCategoriesFilters(args?: CategoriesSearchFilters) {
  if (!args) return undefined;

  return {
    ...(args.s?.length ? { s: args.s } : {}),
    ...(args.counts ? { counts: args.counts } : {}),
    ...(args.actor_ids?.length ? { actor_ids: args.actor_ids.join(',') } : {}),
    ...(args.approved_by?.length ? { approved_by: args.approved_by.join(',') } : {}),
    ...(args.statuses?.length ? { statuses: args.statuses.join(',') } : {}),
    ...(args.skip_level !== undefined ? { skip_level: args.skip_level } : {}),
    ...(args.scopeID && args.scopeID !== selectAllValue ? { scopeID: args.scopeID } : {}),
    ...(args.wsScope ? { wsScope: args.wsScope } : {}),
    ...(args.sub_vendor_id !== undefined ? { sub_vendor_id: args.sub_vendor_id } : {}),
  };
}

class CategoryApiService extends ApiService {
  getCategory = (id: number | string): Promise<AxiosResponse<{ attributes: Record<string, TemplateRule> }>> => {
    return this.get(`/categories/${id}/attributes`);
  };

  searchWalmartCategories = (
    filters: CategoriesSearchFilters
  ): Promise<AxiosResponse<{ categories: Array<SearchedCategory> }>> => {
    return this.get('/categories/search', {
      params: parseCategoriesFilters(filters),
    });
  };

  searchCategories = (
    retailer: RetailerType,
    filters: CategoriesSearchFilters
  ): Promise<AxiosResponse<{ categories: Array<SearchedCategory> }>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/categories/search`, {
      params: parseCategoriesFilters(filters),
    });
  };

  getBenchmarkCategories = (): Promise<AxiosResponse<{ categories: Array<SearchedCategory> }>> => {
    return this.get('/benchmark/team-performance/categories');
  };

  runProductTypeVerificationBatch = (body: ProductTypeVerificationBatchBody) => {
    return this.post('/categories/verification/batches', body);
  };

  runRetailerProductTypeVerificationBatch = (retailer: RetailerType, body: ProductTypeVerificationBatchBody) => {
    return this.post(`/vendors/${retailer.toLowerCase()}/categories/verification/batches`, body);
  };

  postBatchProductCount = (
    body: ProductTypeVerificationBatchBody
  ): Promise<AxiosResponse<{ productCount: number }>> => {
    return this.post('/categories/verification/product-count', body);
  };

  postRetailerBatchProductCount = (
    retailer: RetailerType,
    body: ProductTypeVerificationBatchBody
  ): Promise<AxiosResponse<{ productCount: number }>> => {
    return this.post(`/vendors/${retailer.toLowerCase()}/categories/verification/product-count`, body);
  };

  getProductTypeBatches = (
    params: GetProductTypeBatchesParams
  ): Promise<AxiosResponse<{ batches: ProductTypeBatch[] }>> => {
    return this.get('/categories/verification/batches', {
      params,
    });
  };

  getRetailerProductTypeBatches = (
    retailer: RetailerType,
    params: GetProductTypeBatchesParams
  ): Promise<AxiosResponse<{ batches: ProductTypeBatch[] }>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/categories/verification/batches`, {
      params,
    });
  };

  getProductTypeBatchItems = (
    params: GetProductTypeBatchItemsParams
  ): Promise<AxiosResponse<{ items: ProductTypeBatchItem[] }>> => {
    return this.get(`/categories/verification/batches/${params?.batchID}/items`, {
      params,
    });
  };

  getRetailerProductTypeBatchItems = (
    retailer: RetailerType,
    params: GetProductTypeBatchItemsParams
  ): Promise<AxiosResponse<{ items: ProductTypeBatchItem[] }>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/categories/verification/batches/${params?.batchID}/items`, {
      params,
    });
  };
}

export const categoryApi = new CategoryApiService(process.env.REACT_APP_AI_API);
