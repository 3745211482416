import { Box, Button, IconButton, TextField } from '@mui/material';
import { Styles } from 'common/types/styles';
import { Fragment, ReactElement, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Modal } from 'common/ui/containers/modal';
import { FieldLabelWrapper } from 'common/ui/inputs/field-label-wrapper';
import DeleteIcon from '@mui/icons-material/Delete';
import { useInstructionApplicabilityMutation } from 'common/hooks/api/mutations/use-attributes-mutation';
import { ButtonWithLoading } from 'common/ui/containers/button-with-loading';
import { transformExpressionsForBackend, transfromExpressionsForFrontend } from 'common/services/expressions.service';
import { SelectField, SelectFieldOption } from 'common/ui/inputs/select-field';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import { GenerateSpecialInstructionResponse } from 'common/services/api/attributes/attributes-api.types';
import { useInstructionContext } from '../instruction-context';
import {
  ConditionalInstructionApplicabilityScope,
  initialConditionEntry,
  instructionFormSubmitBtnID,
} from '../constants';
import { ExpressionBlock } from '../expression-block';
import { BooleanOperator } from '../../boolean-operator';
import { ApproveModal } from '../../approve-modal';
import { containerStyles } from '../styles';
import { GenerateInstruction } from '../generate-instruction';
import { InstructionForm } from '../types';

const execScopeOptions: SelectFieldOption[] = [
  {
    label: 'ALL',
    value: ConditionalInstructionApplicabilityScope.All,
  },
  {
    label: 'PDP',
    value: ConditionalInstructionApplicabilityScope.PDP,
  },
  {
    label: 'Attributes',
    value: ConditionalInstructionApplicabilityScope.Attributes,
  },
];

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', ...containerStyles },
  footer: { paddingTop: 2, width: '100%', display: 'flex', justifyContent: 'flex-end', gap: 2 },
  footerBtn: { width: 150 },
  addBtn: { mt: 2, maxWidth: 250, width: '100%' },
  instruction: { display: 'flex', flexDirection: 'column', gap: 2, height: 500, overflowY: 'scroll' },
  expressions: { display: 'flex', flexDirection: 'column', mt: 2, alignItems: 'center', width: '100%' },
  orContainer: { display: 'flex', alignItems: 'center', gap: 0.5 },
};

export function InstructionModal(): ReactElement {
  const { instructionModalOpen, setInstructionModalOpen, selectedInstruction, pid } = useInstructionContext();

  const { control, getValues, setValue } = useFormContext<InstructionForm>();
  const { fields, append, remove } = useFieldArray({ name: 'expressions', control });

  const { mutate: checkApplicability, isLoading: isCheckingApplicability } = useInstructionApplicabilityMutation();

  const [approveOpen, setApproveOpen] = useState<boolean>(false);

  const handleOr = () => {
    append({ conditions: [initialConditionEntry] });
  };

  const handleOrRemove = (index: number) => {
    remove(index + 1);
  };

  const submit = () => {
    document.getElementById(instructionFormSubmitBtnID)?.click();
  };

  const handleSubmit = () => {
    const values = getValues();
    checkApplicability(
      { conditions: transformExpressionsForBackend(values.expressions), pid },
      {
        onSuccess: ({ data }) => {
          if (data.conditionsApplicable) submit();
          else setApproveOpen(true);
        },
      }
    );
  };

  const handleChangeInstruction = (value: GenerateSpecialInstructionResponse) => {
    setValue('name', value?.conditionalInstructionParts?.name ?? '');
    setValue('instruction', value?.conditionalInstructionParts?.instruction ?? '');
    setValue(
      'expressions',
      value?.conditionalInstructionParts?.conditions
        ? transfromExpressionsForFrontend(value?.conditionalInstructionParts?.conditions)
        : [
            {
              conditions: [initialConditionEntry],
            },
          ]
    );
  };

  return (
    <Modal
      title={selectedInstruction?.id === 'new' ? 'Instruction Create' : 'Instruction Edit'}
      open={instructionModalOpen}
      onClose={() => setInstructionModalOpen(false)}
      maxWidth="xl"
    >
      <Box sx={styles.container}>
        <Box sx={styles.instruction}>
          <FieldLabelWrapper label="Execution Scope">
            <Controller
              control={control}
              name="applicabilityScope"
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error?.message}
                  variant="outlined"
                  options={execScopeOptions}
                />
              )}
            />
          </FieldLabelWrapper>
          <FieldLabelWrapper label="Instruction Name">
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </FieldLabelWrapper>
          <FieldLabelWrapper label="Instruction">
            <Controller
              control={control}
              name="instruction"
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  size="small"
                  variant="outlined"
                  maxRows={4}
                  minRows={4}
                  multiline
                />
              )}
            />
          </FieldLabelWrapper>
          <GenerateInstruction
            buttonText="Regenerate Special Instruction"
            generateBttnText="Regenerate"
            onChangeInstruction={handleChangeInstruction}
          />
          <Box sx={styles.expressions}>
            {fields.map((f, i) => (
              <Fragment key={f.id}>
                <ExpressionBlock expressionIndex={i} handleOr={handleOr} handleRemoveExpression={remove} />
                {i !== fields.length - 1 && (
                  <Box sx={styles.orContainer}>
                    <BooleanOperator value="or" />
                    <LightTooltip title="Remove Expression" placement="right">
                      <IconButton size="small" onClick={() => handleOrRemove(i)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </LightTooltip>
                  </Box>
                )}
              </Fragment>
            ))}
          </Box>
        </Box>
        <Box sx={styles.footer}>
          <ButtonWithLoading
            variant="contained"
            sx={styles.footerBtn}
            type="submit"
            onClick={handleSubmit}
            loading={isCheckingApplicability}
          >
            Submit
          </ButtonWithLoading>
          <Button variant="text" sx={styles.footerBtn} onClick={() => setInstructionModalOpen(false)}>
            Cancel
          </Button>
        </Box>
      </Box>
      <ApproveModal
        open={approveOpen}
        onClose={() => setApproveOpen(false)}
        onConfirm={() => {
          submit();
          setApproveOpen(false);
        }}
        vocabulary={{
          yesBtn: 'Yes',
          noBtn: 'Cancel',
          description: 'Updated conditions are not applicable to the current product, do you still want to save ?',
        }}
      />
    </Modal>
  );
}
