import { Box, Tab, Tabs } from '@mui/material';
import { Styles } from 'common/types/styles';
import { ReactElement, SyntheticEvent, useState } from 'react';
import { SelectedContentCtx } from 'common/ui/shared/selected-content-ctx';
import { ProductHead } from './components/product-head';
import { ProductSpecs } from './components/product-specs';
// import { ProductDetails } from './components/product-details';
// import { ProductShippingReturns } from './components/product-shipping-returns';
import { DetailsTabValue } from './constants';
import ProductRightSideHead from './components/product-right-side-head';
import { ModalsContext } from './components/modals-context';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 4 },
  tabs: { backgroundColor: '#FAFAFA', width: 'max-content' },
  tab: { width: 180 },
  head: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
};

export function ProductDetailsPage(): ReactElement {
  const [detailsTab, setDetailsTab] = useState<DetailsTabValue>(DetailsTabValue.Original);

  const handleTabChange = (_: SyntheticEvent<Element, Event>, value: DetailsTabValue) => {
    setDetailsTab(value);
  };

  return (
    <SelectedContentCtx>
      <ModalsContext>
        <Box sx={styles.container}>
          <Box sx={styles.head}>
            <Tabs onChange={handleTabChange} value={detailsTab} sx={styles.tabs} centered>
              <Tab
                value={DetailsTabValue.Original}
                tabIndex={DetailsTabValue.Original}
                label="Original"
                sx={styles.tab}
              />
              <Tab value={DetailsTabValue.Final} tabIndex={DetailsTabValue.Final} label="Final" sx={styles.tab} />
              <Tab value={DetailsTabValue.Compare} tabIndex={DetailsTabValue.Compare} label="Compare" sx={styles.tab} />
            </Tabs>
            <ProductRightSideHead />
          </Box>
          <ProductHead source={detailsTab} />
          <ProductSpecs source={detailsTab} />
          {/* <ProductDetails /> */}
          {/* <ProductShippingReturns /> */}
        </Box>
      </ModalsContext>
    </SelectedContentCtx>
  );
}
