import { Box, IconButton, Popover } from '@mui/material';
import { Styles } from 'common/types/styles';
import { FieldLabelWrapper } from 'common/ui/inputs/field-label-wrapper';
import { Dispatch, memo, MouseEvent, ReactElement, SetStateAction, useState } from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import { SelectField, SelectFieldOption } from 'common/ui/inputs/select-field';
import { TimeRangesType } from 'common/types/common';

const timeRangesOptions: Array<SelectFieldOption> = [
  {
    label: '1w',
    value: TimeRangesType['1w'],
  },
  {
    label: '1m',
    value: TimeRangesType['1m'],
  },
  {
    label: '3m',
    value: TimeRangesType['3m'],
  },
  {
    label: '6m',
    value: TimeRangesType['6m'],
  },
  {
    label: '12m',
    value: TimeRangesType['12m'],
  },
];

const styles: Styles = {
  filter: { display: 'flex', alignItems: 'center', gap: 2 },
  filterInput: { flexGrow: 1 },
  fieldLaberWrapper: { width: '100%' },
  container: { display: 'flex', flexDirection: 'column', gap: 2, p: 2, boxSizing: 'border-box', minWidth: 150 },
};

interface Props {
  setTimeRange: Dispatch<SetStateAction<string>>;
  timeRange: string;
}

export const KWsFilter = memo(({ timeRange, setTimeRange }: Props): ReactElement => {
  const [anchor, setAnchor] = useState<HTMLButtonElement>(null);

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleTimeRangeChange = (values: string) => {
    setTimeRange(values);
    setAnchor(null);
  };

  return (
    <>
      <LightTooltip title="Filters">
        <IconButton onClick={handleOpen} size="small">
          <TuneIcon fontSize="small" />
        </IconButton>
      </LightTooltip>
      <Popover onClose={handleClose} anchorEl={anchor} open={Boolean(anchor)}>
        <Box sx={styles.container}>
          <FieldLabelWrapper label="Time Range" sx={styles.fieldLaberWrapper}>
            <SelectField value={timeRange} onChange={handleTimeRangeChange} options={timeRangesOptions} />
          </FieldLabelWrapper>
        </Box>
      </Popover>
    </>
  );
});
