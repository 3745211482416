import { ApiService } from 'common/services';
import { AxiosResponse } from 'axios';
import { ActionNotification } from 'common/types/common';
import { GetNotificationsParams } from './action-notifications.types';

class ActionNotificationsApiService extends ApiService {
  getNotifications = (
    params: GetNotificationsParams
  ): Promise<AxiosResponse<{ notifications: ActionNotification[] }>> => {
    return this.get('/action-notifications/list', { params });
  };

  stopNotification = (notificationID: number) => {
    return this.post(`/action-notifications/${notificationID}/stop`);
  };
}

export const actionNotificationsApi = new ActionNotificationsApiService(process.env.REACT_APP_AI_API);
