import { getProtectedRoutes, getUnProtectedRoutes } from 'common/constants/routes';
import useSmartLook from 'common/hooks/use-smartlook';
import { ScopeCtx } from 'common/ui/inputs/scopes/scope-ctx';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'root/layout';
import { LoadingContainer } from 'root/layout/loading-container';
import { Redirects } from 'root/redirects';
import * as Sentry from '@sentry/react';
import { RetailerCtx } from 'common/ui/inputs/retailer/retailer-ctx';
import { CQRSRevealResultContext } from 'common/ui/shared/cqrs-reveal-result';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function App() {
  const protectedRoutes = getProtectedRoutes().filter(route => route.isAvailable && !route.hiddenLayout);
  const hiddenLayoutRoutes = getProtectedRoutes().filter(route => route.isAvailable && route.hiddenLayout);
  const unprotectedRoutes = getUnProtectedRoutes();

  useSmartLook();

  return (
    <RetailerCtx>
      <ScopeCtx>
        <CQRSRevealResultContext>
          <Redirects>
            <SentryRoutes>
              <Route element={<Layout />}>
                {protectedRoutes.map(route => (
                  <Route
                    path={route.path}
                    key={route.path}
                    element={
                      <Suspense fallback={<LoadingContainer type="fullwidth" />}>
                        <route.component />
                      </Suspense>
                    }
                  />
                ))}
              </Route>
              {[...unprotectedRoutes, ...hiddenLayoutRoutes].map(route => (
                <Route
                  path={route.path}
                  key={route.path}
                  element={
                    <Suspense fallback={<LoadingContainer type="fullwidth" />}>
                      <route.component />
                    </Suspense>
                  }
                />
              ))}
            </SentryRoutes>
          </Redirects>
        </CQRSRevealResultContext>
      </ScopeCtx>
    </RetailerCtx>
  );
}
