import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { SxProps } from '@mui/system';
import {
  Autocomplete,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Paper,
  PaperProps,
  TextField,
  TextFieldProps,
  Theme,
  autocompleteClasses,
  createFilterOptions,
} from '@mui/material';
import { Styles } from 'common/types/styles';
import { theme } from 'common/constants/theme';
import { usePrevious } from 'common/hooks';
import { compareArrays } from 'common/utils/arrays';
import { useDebouncedCallback } from 'use-debounce';
import { SelectFieldOption } from '../select-field';

const filter = createFilterOptions<SelectFieldOption>();

const styles: Styles = {
  optionText: {
    fontSize: '12px',
  },
  selectedOptionText: {
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightMedium,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  optionIcon: {
    marginRight: theme.spacing(1),
    minWidth: 'unset !important',
  },
  selectedOptionIcon: {
    minWidth: 'unset !important',
    marginRight: theme.spacing(1),
    color: '#000',
  },
  textField: { minHeight: '43px', '[type=text]': { fontSize: 12 } },
  dropdownTexts: { fontSize: 12 },
  paper: {
    [`& .${autocompleteClasses.noOptions}`]: {
      fontSize: 12,
    },
    [`& .${autocompleteClasses.loading}`]: {
      fontSize: 12,
    },
  },
};

function CustomPaper({ children }: PaperProps) {
  return (
    <Paper style={{ minWidth: 'fit-content' }} sx={styles.paper}>
      {children}
    </Paper>
  );
}

interface Props<T> {
  options: SelectFieldOption[];
  value: T;
  onChange: (value: T) => void;
  multiple?: boolean;
  error?: string;
  sx?: SxProps<Theme>;
  inputSx?: SxProps<Theme>;
  label?: string;
  customFilterCb?: (input: string) => void;
  placeholder?: string;
  loading?: boolean;
  disableClearable?: boolean;
  disabled?: boolean;
  variant?: TextFieldProps['variant'];
}

export function AutocompleteField<T = unknown>({
  options,
  value,
  onChange,
  multiple = false,
  sx,
  error,
  label,
  customFilterCb,
  placeholder,
  loading,
  disableClearable = true,
  disabled,
  variant,
  inputSx,
}: Props<T>): ReactElement {
  const [autocompleteValue, setAutocompleteValue] = useState<SelectFieldOption | SelectFieldOption[] | null>(
    multiple ? [] : null
  );
  const [inputValue, setInputValue] = useState<string>('');

  const previousValue = usePrevious(value);

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: SelectFieldOption | SelectFieldOption[]) => {
    let v;

    if (value) {
      v = Array.isArray(value) ? value.map(v => v.value) : value.value;
    } else {
      v = multiple ? [] : '';
    }

    onChange(v as never);
    setAutocompleteValue(value);
  };

  const handleIsOptionSelected = (optionValue: string | number) => {
    return Array.isArray(value) ? value.some(item => item === optionValue) : value === optionValue;
  };

  const getSelectedOptionIcon = () => {
    return Array.isArray(autocompleteValue) ? null : options.find(o => o.value === autocompleteValue?.value)?.icon;
  };

  const debouncedCustomFilter = useDebouncedCallback((search: string) => {
    if (customFilterCb) customFilterCb(search);
  }, 400);

  const handleInputChange = (_: SyntheticEvent, value: string) => {
    setInputValue(value);
    if (customFilterCb) debouncedCustomFilter(value);
  };

  useEffect(() => {
    const isArray = Array.isArray(value) && Array.isArray(autocompleteValue);
    const isSame = isArray
      ? compareArrays(value, autocompleteValue.map(av => av?.value).filter(Boolean))
      : value === (autocompleteValue as SelectFieldOption)?.value;

    if (isSame) return;

    if (Array.isArray(value)) {
      const selectedOptions = value.map(v => options.find((o: SelectFieldOption) => o.value === v));
      setAutocompleteValue(selectedOptions.length ? selectedOptions : []);
    } else {
      const selectedOption = options.find((o: SelectFieldOption) => o.value === value);
      setAutocompleteValue(selectedOption ?? null);
    }
    // Do not check autocompleteValue for updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, previousValue, value]);

  return (
    <Autocomplete
      size="small"
      onChange={handleChange}
      filterOptions={customFilterCb ? x => x : filter}
      onInputChange={handleInputChange}
      sx={[styles.autocomplete, ...(Array.isArray(sx) ? sx : [sx])]}
      disabled={disabled}
      disableCloseOnSelect={Array.isArray(autocompleteValue) && multiple}
      // eslint-disable-next-line react/no-unstable-nested-components
      renderInput={params => (
        <TextField
          {...params}
          error={Boolean(error)}
          helperText={error}
          label={label}
          placeholder={placeholder}
          variant={variant}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: Array.isArray(value) ? Boolean(value.length) : Boolean(value),
          }}
          InputProps={{
            ...params.InputProps,
            sx: [styles.textField, ...(Array.isArray(inputSx) ? inputSx : [inputSx])],
            startAdornment: (
              <>
                {!Array.isArray(value) && !multiple && getSelectedOptionIcon() && (
                  <InputAdornment position="end">{getSelectedOptionIcon()}</InputAdornment>
                )}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
        />
      )}
      getOptionLabel={(option: SelectFieldOption) => {
        return option?.label || '';
      }}
      renderOption={(props, option) => (
        <li {...props}>
          {option.icon && (
            <ListItemIcon sx={handleIsOptionSelected(option.value) ? styles.selectedOptionIcon : styles.optionIcon}>
              {option.icon}
            </ListItemIcon>
          )}
          <ListItemText
            primary={option.label}
            primaryTypographyProps={{
              sx: handleIsOptionSelected(option.value) ? styles.selectedOptionText : styles.optionText,
            }}
          />
        </li>
      )}
      isOptionEqualToValue={(option, value) => String(option.value) === String(value?.value)}
      value={autocompleteValue}
      inputValue={inputValue}
      options={options}
      multiple={Array.isArray(autocompleteValue) && multiple}
      getOptionKey={o => o?.value ?? o?.label}
      PaperComponent={CustomPaper}
      loading={loading}
      disableClearable={disableClearable}
      forcePopupIcon
    />
  );
}
