import { PropsWithChildren, ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { transfromExpressionsForFrontend, transformExpressionsForBackend } from 'common/services/expressions.service';
import {
  useConditionalInstructionsPatchMutation,
  useConditionalInstructionsPostMutation,
} from 'common/hooks/api/mutations/use-attributes-mutation';
import { UpdateConditionalInstructionsBody } from 'common/services/api/attributes/attributes-api.types';
import toast from 'react-hot-toast';
import { useQueryClient } from '@tanstack/react-query';
import { AttributesQueryKey } from 'common/hooks/api/queries/use-attributes-query';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  expressionsValidationSchema,
  instructionsValidationSchema,
} from 'common/ui/shared/conditional-instructions-modal/validations';
import { validateYupSchema } from 'common/utils/yup';
import { useInstructionContext } from '../instruction-context';
import {
  ConditionalInstructionApplicabilityScope,
  initialConditionEntry,
  instructionFormSubmitBtnID,
} from '../constants';
import { InstructionForm as IInstructionForm } from '../types';

export function InstructionForm({ children }: PropsWithChildren): ReactElement {
  const queryClient = useQueryClient();

  const { selectedInstruction, setInstructionModalOpen } = useInstructionContext();

  const { mutate: mutatePost } = useConditionalInstructionsPostMutation();
  const { mutate: mutatePatch } = useConditionalInstructionsPatchMutation();

  const methods = useForm<IInstructionForm>({
    defaultValues: {
      applicabilityScope: ConditionalInstructionApplicabilityScope.All,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: yupResolver(instructionsValidationSchema) as any,
    mode: 'onChange',
  });

  const onSubmit = (data: IInstructionForm) => {
    const body: UpdateConditionalInstructionsBody = {
      name: data.name,
      instruction: data.instruction,
      conditions: transformExpressionsForBackend(data.expressions),
      applicabilityScope: data.applicabilityScope,
      ...(data.id === 'new' ? {} : { id: data.id }),
    };

    const handleSuccess = () => {
      queryClient.invalidateQueries([AttributesQueryKey.ConditionalInstructions]);
      queryClient.invalidateQueries([AttributesQueryKey.ProductSpecialInstructions]);
      toast.success('Conditional instruction saved successfully');
    };

    setInstructionModalOpen(false);

    if (data.id === 'new') mutatePost(body, { onSuccess: handleSuccess });
    else mutatePatch(body, { onSuccess: handleSuccess });
  };

  useEffect(() => {
    if (!selectedInstruction) return;

    let expressions = selectedInstruction.conditions
      ? transfromExpressionsForFrontend(selectedInstruction.conditions)
      : null;

    // in case of invalid expressions from backend fallback to empty
    if (!validateYupSchema(expressionsValidationSchema, expressions) || !expressions.length) {
      expressions = [
        {
          conditions: [initialConditionEntry],
        },
      ];
    }

    methods.reset({
      name: selectedInstruction.name,
      id: selectedInstruction.id,
      instruction: selectedInstruction.instruction,
      applicabilityScope: selectedInstruction.applicability_scope,
      expressions,
    });
  }, [methods, selectedInstruction]);

  return (
    <FormProvider {...methods}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children}
        <button type="submit" style={{ display: 'none' }} id={instructionFormSubmitBtnID}>
          submit
        </button>
      </form>
    </FormProvider>
  );
}
