import { CONTENT_PL_PR, CONTENT_PT_PB } from 'common/constants/sizes';
import { usePathsMatch } from './use-paths-match';

const routesWithoutPadding = ['/content/quick-review', '/compare-benchmark', '/pdp-gen', '/pdp-gen/:pipelineID'];

export function useContentPadding(initial = true) {
  const hiddenPadding = usePathsMatch(routesWithoutPadding, initial);

  return { paddingX: hiddenPadding ? 0 : CONTENT_PL_PR, paddingY: hiddenPadding ? 0 : CONTENT_PT_PB };
}
