export enum ContentStatus {
  Failed = 'Failed',
  ReadyForAI = 'ReadyForAI',
  ReadyForReview = 'ReadyForReview',
  ReadyForUpload = 'ReadyForUpload',
  Uploaded = 'Uploaded',
  Issues = 'Issues',
  NoIssues = 'NoIssues',
  DoneDone = 'Done',
  New = 'New',
}

export enum ProductContentStatus {
  NoIssues = -7,
  New = 1,
  ReadyForAI = 2,
  Failed = 3,
  ReadyForReview = 4,
  ReadyForUpload = 5,
  Uploaded = 6,
  Issues = 7,
  Done = 8,
}

interface StatusMapping {
  label: ContentStatus;
  value: ProductContentStatus;
}

export const сontentStatusData = (): StatusMapping[] => {
  const result: StatusMapping[] = [];

  Object.entries(ContentStatus).forEach(([key, label]) => {
    if (key in ProductContentStatus) {
      result.push({
        label: label as ContentStatus,
        value: ProductContentStatus[key as keyof typeof ProductContentStatus],
      });
    }
  });

  return result;
};

export const StatusIDNameMap: Record<ProductContentStatus, ContentStatus> = {
  [ProductContentStatus.NoIssues]: ContentStatus.NoIssues,
  [ProductContentStatus.New]: ContentStatus.New,
  [ProductContentStatus.ReadyForAI]: ContentStatus.ReadyForAI,
  [ProductContentStatus.Failed]: ContentStatus.Failed,
  [ProductContentStatus.ReadyForReview]: ContentStatus.ReadyForReview,
  [ProductContentStatus.ReadyForUpload]: ContentStatus.ReadyForUpload,
  [ProductContentStatus.Uploaded]: ContentStatus.Uploaded,
  [ProductContentStatus.Issues]: ContentStatus.Issues,
  [ProductContentStatus.Done]: ContentStatus.DoneDone,
};

export const StatusNameIDMap: Record<ContentStatus, ProductContentStatus> = {
  [ContentStatus.NoIssues]: ProductContentStatus.NoIssues,
  [ContentStatus.New]: ProductContentStatus.New,
  [ContentStatus.ReadyForAI]: ProductContentStatus.ReadyForAI,
  [ContentStatus.Failed]: ProductContentStatus.Failed,
  [ContentStatus.ReadyForReview]: ProductContentStatus.ReadyForReview,
  [ContentStatus.ReadyForUpload]: ProductContentStatus.ReadyForUpload,
  [ContentStatus.Uploaded]: ProductContentStatus.Uploaded,
  [ContentStatus.Issues]: ProductContentStatus.Issues,
  [ContentStatus.DoneDone]: ProductContentStatus.Done,
};

// keys in the tags field
export enum ProductFlagKey {
  WrongProductType = 'flag:invalid',
  PrimaryShelfMisalignment = 'flag:psma',
  CannotBeEnriched = 'flag:nenr',
  Example = 'flag:example',
}

// actual value of flag to use in filters
export enum ProductFlagValue {
  WrongProductType = 'flag:invalid@ipt',
  Warnings = 'flag:warning',
  InsuficcientInfo = 'flag:invalid@isf',
  PrimaryShelfMisalignment = 'flag:psma',
  CannotBeEnriched = 'flag:nenr',
  Example = 'flag:example',
}

export enum QuickReviewType {
  InitialCheck = 'InitialCheck',
  SpotCheck = 'SpotCheck',
  L3 = 'L3',
}

export enum POCSubVendors {
  Costco = 1,
  Amazon = 2,
  Suplery = 3,
}

export enum RetailerType {
  Walmart = 'Walmart',
  Flywheel = 'Flywheel',
  Target = 'Target',
  Instacart = '1',
  Autozone = '3',
  POC = 'POC',
  Amazon = 'Amazon',
  Costco = 'Costco',
  Suplery = 'Suplery',
  SyndigoTarget = '2',
  SyndigoWalmart = '4',
  SyndigoAmazon = '5',
  SyndigoKroger = '6',
}

export const WalmartRetailers = [RetailerType.Walmart, RetailerType.Flywheel];

export const PocRetailers = [RetailerType.Amazon, RetailerType.Costco, RetailerType.Suplery];

export const SyndigoRetailers = [
  RetailerType.SyndigoAmazon,
  RetailerType.SyndigoKroger,
  RetailerType.SyndigoTarget,
  RetailerType.SyndigoWalmart,
];

export const SubRetailers = [...SyndigoRetailers, RetailerType.Autozone, RetailerType.Instacart];

export const RetailersTitleMap = {
  [RetailerType.Walmart]: 'Walmart',
  [RetailerType.Flywheel]: 'Walmart (Flywheel)',
  [RetailerType.Target]: 'Target',
  [RetailerType.Instacart]: 'Instacart',
  [RetailerType.Autozone]: 'Autozone',
  [RetailerType.POC]: 'POC',
  [RetailerType.Amazon]: 'Amazon',
  [RetailerType.Costco]: 'Costco',
  [RetailerType.Suplery]: 'Suplery',
  [RetailerType.SyndigoTarget]: 'Syndigo (Target)',
  [RetailerType.SyndigoWalmart]: 'Syndigo (Walmart)',
  [RetailerType.SyndigoAmazon]: 'Syndigo (Amazon)',
  [RetailerType.SyndigoKroger]: 'Syndigo (Kroger)',
};

export const AllVendorsTitleMap = {
  [RetailerType.Walmart]: { label: 'Walmart', value: 'Walmart', id: 1 },
  [RetailerType.Flywheel]: { label: 'Walmart (Flywheel)', value: 'Flywheel', id: 12 },
  [RetailerType.Target]: { label: 'Target', value: 'Target', id: 2 },
  [RetailerType.Instacart]: { label: 'Instacart', value: '1', id: 4 },
  [RetailerType.Autozone]: { label: 'Autozone', value: '3', id: 5 },
  Instacart: { label: 'Instacart', value: '1', id: 4 },
  Autozone: { label: 'Autozone', value: '3', id: 5 },
  [RetailerType.POC]: { label: 'POC', value: 'POC', id: 3 },
  [RetailerType.Amazon]: { label: 'Amazon', value: 'Amazon', id: 11 },
  [RetailerType.Costco]: { label: 'Costco', value: 'Costco', id: 10 },
  [RetailerType.Suplery]: { label: 'Suplery', value: 'Suplery', id: 13 },
  [RetailerType.SyndigoTarget]: { label: 'Syndigo (Target)', value: '2', id: 6 },
  [RetailerType.SyndigoWalmart]: { label: 'Syndigo (Walmart)', value: '4', id: 7 },
  [RetailerType.SyndigoAmazon]: { label: 'Syndigo (Amazon)', value: '5', id: 8 },
  [RetailerType.SyndigoKroger]: { label: 'Syndigo (Kroger)', value: '6', id: 9 },
  'Syndigo - Target': { label: 'Syndigo (Target)', value: '2', id: 6 },
  'Syndigo - Walmart': { label: 'Syndigo (Walmart)', value: '4', id: 7 },
  'Syndigo - Amazon': { label: 'Syndigo (Amazon)', value: '5', id: 8 },
  'Syndigo - Kroger': { label: 'Syndigo (Kroger)', value: '6', id: 9 },
};

export enum IssuesType {
  All = 'All',
  IssuesOnly = 'Issues Only',
  NonIssuesOnly = 'Non-Issues Only',
}

export const selectAllValue = 'All';

export const RouteRetailersMap: Record<string, RetailerType[]> = {
  '/pdp-gen': [RetailerType.Amazon, RetailerType.Target],
  '/pdp-gen/:pipelineID': [RetailerType.Amazon, RetailerType.Target],
};
