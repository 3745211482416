import { useMutation } from '@tanstack/react-query';
import { cqrsApiService } from 'common/services/api/cqrs/cqrs-api.service';
import {
  EnsureProductResult,
  FetchExternalAttrResult,
  ImportSyndigoResult,
  SanitizeScopeResult,
  ScrapeFromURLResult,
  SuggestCategoryResult,
} from 'common/services/api/cqrs/cqrs-api.types';

export enum CQRSQueryKey {
  Result = 'Result',
}

export function useCQRSResultMutation<Result>() {
  const query = useMutation({
    mutationFn: async (id: string) => {
      const { data } = await cqrsApiService.getResult<Result>(id);
      return data;
    },
  });

  return query;
}

export function useAmazonProductImportResultMutation() {
  return useCQRSResultMutation<SuggestCategoryResult>();
}

export function useSuggestCategoryResultMutation() {
  return useCQRSResultMutation<SuggestCategoryResult>();
}

export function useEnsureProductResultMutation() {
  return useCQRSResultMutation<EnsureProductResult>();
}

export function useScrapeFromURLResultMutation() {
  return useCQRSResultMutation<ScrapeFromURLResult>();
}

export function useSanitizeScopeResultMutation() {
  return useCQRSResultMutation<SanitizeScopeResult>();
}

export function useCQRSProductImportMutation() {
  return useCQRSResultMutation<ImportSyndigoResult>();
}

export function useCQRSFetchExternalAttrMutation() {
  return useCQRSResultMutation<FetchExternalAttrResult>();
}
