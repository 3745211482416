import { useMutation } from '@tanstack/react-query';
import { actionNotificationsApi } from 'common/services/api/action-notifications/action-notifications.service';

export function useStopActionNotificationMutation() {
  const mutation = useMutation({
    mutationFn: async (id: number) => {
      const { data } = await actionNotificationsApi.stopNotification(id);
      return data;
    },
  });

  return mutation;
}
