import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { RetailerType } from 'common/constants/entities';
import { userService } from 'common/services/user.service';
import { SavedKeyword, TimeRangesType } from 'common/types/common';
import { Styles } from 'common/types/styles';
import { getKWsRegex } from 'common/ui/shared/seo-comparator/regex';
import { stringToHEX } from 'common/utils/color';
import { ReactElement, useMemo } from 'react';

const styles: Styles = {
  kw: { cursor: 'pointer' },
};

interface Props {
  kwTimeRange?: string;
  beforeSEOTitle: string;
  afterSEOTitle: string;
  beforeSEODesc: string;
  afterSEODesc: string;
  beforeSEOFeats: string;
  afterSEOFeats: string;
  keywords: SavedKeyword[];
  highlightedKW: string;
  setHightlightedKW: (kw: string) => void;
}

export function KeywordsTable({
  keywords,
  beforeSEOTitle,
  afterSEOTitle,
  beforeSEODesc,
  afterSEODesc,
  beforeSEOFeats,
  afterSEOFeats,
  highlightedKW,
  kwTimeRange,
  setHightlightedKW,
}: Props): ReactElement {
  const beforeCount: Record<string, number> = useMemo(() => {
    return keywords.reduce((acc, item) => {
      if (!acc[item.keyword]) acc[item.keyword] = 0;
      const regex = getKWsRegex(item.keyword);
      acc[item.keyword] += beforeSEOTitle.match(regex)?.length ?? 0;
      acc[item.keyword] += beforeSEODesc.match(regex)?.length ?? 0;
      acc[item.keyword] += beforeSEOFeats.match(regex)?.length ?? 0;
      return acc;
    }, {} as Record<string, number>);
  }, [beforeSEODesc, beforeSEOFeats, beforeSEOTitle, keywords]);

  const afterCount: Record<string, number> = useMemo(() => {
    return keywords.reduce((acc, item) => {
      if (!acc[item.keyword]) acc[item.keyword] = 0;
      const regex = getKWsRegex(item.keyword);
      acc[item.keyword] += afterSEOTitle.match(regex)?.length ?? 0;
      acc[item.keyword] += afterSEODesc.match(regex)?.length ?? 0;
      acc[item.keyword] += afterSEOFeats.match(regex)?.length ?? 0;
      return acc;
    }, {} as Record<string, number>);
  }, [afterSEODesc, afterSEOFeats, afterSEOTitle, keywords]);

  const getSfr = (kw: Partial<SavedKeyword>) => {
    switch (kwTimeRange) {
      case TimeRangesType['1w']:
        return kw?.past_week_sfr;
      case TimeRangesType['1m']:
        return kw?.past_month_sfr;
      case TimeRangesType['3m']:
        return kw?.past_3_months_sfr;
      case TimeRangesType['6m']:
        return kw?.past_6_months_sfr;
      case TimeRangesType['12m']:
        return kw?.past_12_months_sfr;
      default:
        return kw?.sfr;
    }
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Keyword</TableCell>
          <TableCell>Before SEO</TableCell>
          <TableCell>After SEO</TableCell>
          {userService.ensureRetailer(RetailerType.Target) && (
            <>
              <TableCell>Target Volume [1m]</TableCell>
              <TableCell>Target Volume [6m]</TableCell>
            </>
          )}
          <TableCell>Amazon SFR</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {keywords
          .filter(k => Boolean(beforeCount[k.keyword]) || Boolean(afterCount[k.keyword]))
          .map(k => (
            <TableRow key={k.keyword}>
              <TableCell
                sx={styles.kw}
                onClick={() => setHightlightedKW(highlightedKW === k.keyword ? null : k.keyword)}
              >
                <Typography
                  fontSize={12}
                  sx={[k.keyword === highlightedKW && { backgroundColor: stringToHEX(k.keyword) }].filter(Boolean)}
                >
                  {k.keyword}
                </Typography>
              </TableCell>
              <TableCell>{beforeCount[k.keyword] ?? 0}</TableCell>
              <TableCell>{afterCount[k.keyword] ?? 0}</TableCell>
              {userService.ensureRetailer(RetailerType.Target) && (
                <>
                  <TableCell>{k.latest_month_volume ?? '-'}</TableCell>
                  <TableCell>{k.past_six_months_volume ?? '-'}</TableCell>
                </>
              )}
              <TableCell>{getSfr(k) ? getSfr(k) : '-'}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
