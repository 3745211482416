import { ReactElement, useMemo } from 'react';
import { AutocompleteField } from 'common/ui/inputs/autocomplete-field';
import { OmitedCategoriesSearchFilters, useCategoriesOptionsSearch } from 'common/hooks/use-categories-options-search';
import { SystemStyleObject } from '@mui/system';
import { TextFieldProps } from '@mui/material';

interface Props<T> {
  setValue: (value: T) => void;
  value: T;
  searchFilters?: OmitedCategoriesSearchFilters;
  sx?: SystemStyleObject;
  inputSx?: SystemStyleObject;
  globalSearch?: boolean;
  error?: string;
  multiple?: boolean;
  variant?: TextFieldProps['variant'];
  useName?: boolean;
}

export function CategoriesSelect<T = unknown>({
  setValue,
  value,
  searchFilters,
  sx,
  globalSearch,
  error,
  multiple,
  variant,
  useName,
  inputSx,
}: Props<T>): ReactElement {
  const categoryIDs = useMemo(() => {
    return Array.isArray(value) ? value : [value as number];
  }, [value]);

  const { categoriesOptions, setCategoriesSearch, isCategoriesFetching } = useCategoriesOptionsSearch({
    filters: searchFilters,
    categoryIDs,
    useName,
    globalSearch,
  });

  return (
    <AutocompleteField
      value={value}
      onChange={setValue}
      sx={sx}
      inputSx={inputSx}
      options={categoriesOptions}
      customFilterCb={setCategoriesSearch}
      placeholder="Start typing..."
      loading={isCategoriesFetching}
      disableClearable={false}
      error={error}
      multiple={multiple}
      variant={variant}
    />
  );
}
