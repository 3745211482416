import { createContext, PropsWithChildren, ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import { useCQRSResultMutation } from 'common/hooks/api/mutations/use-cqrs-mutation';
import { useLocation } from 'react-router-dom';
import { ApproveModal } from '../approve-modal';

const cqrsRevealResultCtx = createContext({
  addCommand: null as (id: string, name: string) => void,
});

interface CQRSCommandEntry {
  id: string;
  initiatorPath: string;
  name: string;
  done?: boolean;
}

export function CQRSRevealResultContext({ children }: PropsWithChildren): ReactElement {
  const location = useLocation();

  const [commandsEntries, setCommandsEntries] = useState<CQRSCommandEntry[]>([]);

  const { mutate: checkCQRS } = useCQRSResultMutation<unknown>();

  const addCommand = useCallback(
    (id: string, name: string) => {
      setCommandsEntries(commandsEntries => [...commandsEntries, { id, initiatorPath: location.pathname, name }]);

      const interval = window.setInterval(() => {
        checkCQRS(id, {
          onSuccess: ({ result, error, completed_at }) => {
            if (error) {
              window.clearInterval(interval);
            }

            if (result || completed_at) {
              setCommandsEntries(commandsEntries =>
                commandsEntries.map(commandEntry =>
                  commandEntry.id === id ? { ...commandEntry, done: true } : commandEntry
                )
              );
              window.clearInterval(interval);
            }
          },
          onError: () => {
            window.clearInterval(interval);
          },
        });
      }, 5000);
    },
    [checkCQRS, location.pathname]
  );

  const closeCommand = (id: string, reveal?: boolean) => {
    if (reveal) {
      window.location.pathname = commandsEntries.find(commandEntry => commandEntry.id === id).initiatorPath;
    }
    setCommandsEntries(commandsEntries => commandsEntries.filter(commandEntry => commandEntry.id !== id));
  };

  const value = useMemo(() => {
    return {
      addCommand,
    };
  }, [addCommand]);

  return (
    <cqrsRevealResultCtx.Provider value={value}>
      {commandsEntries
        .filter(commandEntry => commandEntry.done)
        .map(commandEntry => (
          <ApproveModal
            onClose={() => closeCommand(commandEntry.id)}
            onConfirm={() => closeCommand(commandEntry.id, true)}
            vocabulary={{
              yesBtn: 'Reveal',
              noBtn: 'Close',
              description: `${commandEntry.name} job which was initiated on the "${commandEntry.initiatorPath}" is finished, do you want to reveal the result?`,
            }}
            open
          />
        ))}
      {children}
    </cqrsRevealResultCtx.Provider>
  );
}

export function useCQRSRevealContext() {
  const ctx = useContext(cqrsRevealResultCtx);
  return ctx;
}
