import * as yup from 'yup';

export function validateYupSchema(schema: yup.AnySchema, value: unknown): boolean {
  try {
    schema.validateSync(value);
    return true;
  } catch (err) {
    return false;
  }
}
