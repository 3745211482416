import { AxiosResponse } from 'axios';
import { ApiService } from 'common/services';
import { CQRSResultResponse } from './cqrs-api.types';

class CQRSApiService extends ApiService {
  getResult = <Result>(id: string): Promise<AxiosResponse<CQRSResultResponse<Result>>> => {
    return this.get(`/cqrs/result/${id}`, { params: { includeErrorPayload: true } });
  };
}

export const cqrsApiService = new CQRSApiService(process.env.REACT_APP_AI_API);
