import { HEAD_HEIGHT } from 'common/constants/sizes';
import { usePathsMatch } from './use-paths-match';

const routesWithoutHeader = ['/content/quick-review', '/background-creator', '/pdp-gen', '/pdp-gen/:pipelineID'];

export function useHeadHeight() {
  const hiddenHeader = usePathsMatch(routesWithoutHeader);

  return hiddenHeader ? 0 : HEAD_HEIGHT;
}
